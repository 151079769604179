@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito:wght@400;700&display=swap');

/* Includes styling for the dashboard, CREDITZ, and CREDITZ Pricing table*/



  /* CREDITZ PAGES */

  .CREDITZ-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px 40px 40px 40px;
  }
   
  .CREDITZ-box {
    width: 100%;
    min-height: 400px;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 50px 50px 50px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 50%; /* Increase top margin to push the box down */
  
    }

  .CREDITZ-button {   /* Adjusts the size of the buttons on CREDITZ page */
    width: 195px; /* Decrease width */
    height: 150px; /* Decrease height */
    display: flex;
    flex-direction: column;
    justify-content: center; 
    font-family: 'Nunito', sans-serif;
    color: #ffb453;
    font-size: 16px;
    font-weight: 550;
    align-items: center;
    padding: 20px; /* Adjust padding as needed */
    border: 3px solid #EEEEEE;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #951eb5;
    cursor: pointer;
    margin-right: 20px; /* Adjust margin as needed */
    margin-bottom: 20px; /* Adjust margin as needed */
   
    }
  
    .CREDITZ-button:hover {
      border: 3px solid #fc8320;
      background-color: #521887;
      color: #fbfafc ;
    }

  
  .CREDITZ-button:hover .creditz-agreements{
    color: #fbfafc;
  }
  
  .CREDITZ-button .creditz-agreements{
    font-size: 12px; /* Adjust the font size */
    color: #fbfafc; /* Adjust the text color */
    margin-top: 10px; /* Adjust the spacing between the button text and the paragraph */
    /* Add other styles as needed */
  }

  .button-groupCreditz{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-rowCreditz{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
  }
  

  /* Add additional styles for specific buttons if needed */

  
  
  /* ... Add more classes for other buttons and elements as needed ... */
  

  
 

  /* CREDITZ PRICING TABLE */

  .CREDITZ-intro {
    width: 100%;
    min-height: 400px;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 60px 50px 50px;
    display: flex;
    flex-direction: column;
    margin-top: 150px; /* Increase top margin to push the box down */
  
    }

     
  .buy-button { /*   */
    background-color: #fc8320;
    margin-top: none;
    color: white;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 20%;
    height: 10%;
    justify-content: center;
  }

    .creditz-details {
      display: flex;
      font-size: 20px; /* Adjust the font size */
      color: #2c3e50;
      justify-content: center;
      align-items: center;
      margin-top: 5px; /* Adjust the top margin */
    }

    .caveats {
      font-size: 12px; /* Adjust the font size */
      color: #2c3e50;
      margin-top: 5px; /* Adjust the top margin */
    }
  .pricing-tables-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Adjust as needed for spacing between tables */
    align-items: flex-start; /* Align tables at the top */
    flex-wrap: wrap; /* Wrap to the next line if there are too many tables for one row */
    gap: 5px; /* Space between tables */
  }

  .credpage-button { /*   */
    background-color: #d21dd2;
    margin-top: 3%;
    color: white;
    font-size: 16px;
    font-weight: 500px;
    border-radius: 6px;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 15%;
    height: 10%;

  }

  .dashboardb-button { /*   */
    background-color: #fc8320;
    margin-top: 3%;
    color: white;
    font-size: 16px;
    font-weight: 600px;
    border-radius: 6px;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 10%;
    height: 20%;

  }



  .credpage-button:hover {
    background-color: #2c3e50;
  }
  