  
  /* Auth pages

 /* General formats */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito:wght@400;700&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  background: #951eb5 no-repeat center center fixed;
  background-size: cover;
  height: 70vh;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px; /* Add bottom padding */
}

p {
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px; 

}


/*** Styles Sign In, VerifyEmail, Request Password */

.form-style { /* white background container */
  width: 400px; /* Fixed width */
  height: auto; /* Adjusted for variable content */
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 30px; /* Consistent padding */
  display: flex;
  flex-direction: column;
  margin-top: 50%; /* Consistent margin from top */
  margin-left: auto; /* Centering the form horizontally */
  margin-right: auto;
}


.form-style h2 {  /* heading for container.  */
  text-align: center;
  font-size: 20px;
  color: #fc8320;
}

.form-style h1 {  /* heading for container.  */
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  color: #fc8320;
}

.form-style p {  /* heading for container.  */
  text-align: center;
  font-size: 14px;
  font-weight: 100;
  color: #2c3e50;
  margin-bottom: 10px;
}


.form-style label { /* field labels  */
  display: block;
  margin-top: 10px;
  color: #2c3e50;
  text-align: left;
  margin-bottom: 3px;
  font-family: 'Nunito', sans-serif;
}

.form-style input[type="text"], 
.form-style input[type="password"], 
.form-style input[type="email"] {
  width: 90%;
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  margin: 10px 0;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-style button { /* for any submit button  */
  width: auto;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #fc8320;
  color: white;
  text-transform: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin: 20px auto;
}

  .form-style button:hover {  /* hover for any submit button  */
    background-color: #951eb5;
  }


  .forgot-password-link { /* signin page  */
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    color: #fc8320;
    margin-top: 20px; 
  
  }

  .submit-button {
    padding: 15px;
    background-color: #951eb5;; /* Example primary color */
    color: white;
    border: none;
    border-radius: 8px;
    font-family: 'NunitoSans', sans-serif;   
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
  }


  .submit-button:hover {
    background-color: #951eb5; /* Darker shade for hover effect */
    color: white;
  }


/* Layout Styling */
.dashboard-layout {
  display: flex;
  height: 30%; /* Full viewport height */
  overflow: hidden;
  margin-top: 10%;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f8fafc; /* Light background */
  overflow-y: auto; /* Scroll if content overflows */
}


 /* Total Sidebar styles */
 .sidebar {
  height: 100%; /* Full height */
  width: 275px; /* Sidebar width */
  margin-top: 5.2%;
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  background-color: #521887ff; /* Dark background */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 6%;
}

  /* Style for SIDE BAR PANEL */
/* Sidebar links */
.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 520;
  margin-left: 15px;
  color: whitesmoke;
  display: block; /* Make links stack vertically */
}

/* Hover effect for links */
.sidebar a:hover {
  background-color: #8ee13f;
}



  .wordpress-style { /* white background container */
    width: 600px; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
    margin-top: 30%; /* Consistent margin from top */
    margin-left: auto; /* Centering the form horizontally */
    margin-right: auto;
   
  }


  
  stripe-pricing-table {
    display: flex !important;
  justify-content: center !important;
 
  flex-direction: row !important; /* Forces horizontal layout */
  width: 100% !important;
  max-width: 1200px; /* Adjust width if necessary */
  }
 

    .verify-message { /* email is verified */
      font-size: 50px;
      font-family: 'Nunito', sans-serif;
      text-align: center;
      color: #fc8320;
      margin-top: 5px; 
    
    }

    .email-style {
      font-size: 16px;
      font-family: 'Nunito', sans-serif;
      color: #fc8320;
      text-decoration: underline;
      font-weight: 400;
    
    }
  
  




    .submitme-button {
      padding: 14px;
      
      color: white;
      border: none;
      border-radius: 8px;
      font-family: 'NunitoSans', sans-serif;   
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
      cursor: pointer;
    }




  

/************ MEDIA QUERIES *************** MEDIA QUERIES *******************/

/* For average Ipad screens */

   @media only screen and (min-width: 1024px) and (max-width: 1366px) {

    .form-style { /* white background container */
      width: 100%; /* Fixed width */
      height: auto; /* Adjusted for variable content */
      padding: 30px; /* Consistent padding */
      display: flex;
      flex-direction: column;
      margin-top: auto; /* Consistent margin from top */
      margin-left: auto; /* Centering the form horizontally */
      margin-right: auto;
      
      }

      p {
        font-size: 20px;
        font-family: 'Nunito', sans-serif;
        text-align: center;
        color: #2c3e50;
        margin-top: 20px; 
      
      }


   }




@media only screen and (min-width: 769px) and (max-width: 1023px) {

  /* example iPad  */

      .form-style { /* white background container */
        width: 100%; /* Fixed width */
        height: auto; /* Adjusted for variable content */
        padding: 30px; /* Consistent padding */
        display: flex;
        flex-direction: column;
        margin-top: 5%; /* Consistent margin from top */
        margin-left: auto; /* Centering the form horizontally */
        margin-right: auto;
        
        }

        .form-style input[type="text"], 
        .form-style input[type="password"], 
        .form-style input[type="email"] {
          width: 100%;
          padding: 10px;
          font-size: 14px;
          color: #2c3e50;
          margin: 10px 0;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-sizing: border-box;
        }

      


  
    .dashboard-layout {
      display: flex;
  height: 100%;
  overflow: hidden;
  margin-top: 50%;
    }
  
    .sidebar {
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: #d211d2;
    }

    .sidebar h2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    
    .sidebar ul {
      list-style: none;
      padding: 0;
    }
    
    .sidebar ul li {
      margin-bottom: 10px;
    }
    
    .sidebar a {
      text-decoration: none;
      color: white;
      padding: 10px;
      display: block;
      border-radius: 5px;
      transition: background 0.3s;
    }
    
  
    .main-content {
      margin-top: 30%;
      padding: 20px;
      background-color: #f8fafc;
      overflow-y: auto;
    }
  
  
}

    

@media screen and (min-width: 481px) and (max-width: 768px)  {

  /* example iPad  */

  .form-style { /* white background container */
    width: 75%; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
    margin-top: auto; /* Consistent margin from top */
    margin-left: auto; /* Centering the form horizontally */
    margin-right: auto;
    
    }
    
  .form-style input[type="text"], 
  .form-style input[type="password"], 
  .form-style input[type="email"] {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    margin: 10px 0;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }

  form-style p {
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    margin-top: 20px; 
  
  }

.container {
  display: flex;
  margin-top: 10%;
  margin-left: 100px;
  margin-right: 190px;
  justify-content: center; /* Centers the content horizontally */
  align-items: start; /* Aligns items to the top */
  padding: 20px;
  flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
}




}
   @media only screen and (max-width: 480px) { 

    /* example Galaxy S5 + Iphone SE */
     /* Sign Up Home Container */

     .form-style { /* background */
      width: 70%; /* Fixed width */
      height: auto; /* Adjusted for variable content */
      display: flex;
      margin-top: auto; /* Consistent margin from top */
    }

    .form-style h2 {  /* Media query.  */
      text-align: center;
      font-size: 16px;
}

    .form-style label {  /* Media query.  */
      display: block;
      margin-top: 10px;
      font-size: 13px;
      color: #2c3e50;

    }

    body 
    {
    min-height: 100%;
    padding-bottom: 100px; /* Add bottom padding */
    overflow-x: hidden;
  }
    

  .form-style label {  /* Media query.  */
    display: block;
    margin-top: 10px;
    font-size: 12px;
    color: #2c3e50;
  
  }
  


    
   }

