  
  /* design for Sign Up HOME page */


  /* Sign Home Container */
    /* SignHome.js */

  .sign-home {
      display: flex;
      margin-top: 20%;
      margin-left: 190px;
      justify-content: center; /* Centers the content horizontally */
      align-items: start; /* Aligns items to the top */
      padding: 20px;
      flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
  }

 /* This is the white form on the SignHome page */

  .container-style {
    width: 70%; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
    margin-left: none; /* Centering the form horizontally */
    margin-right: auto;
  }


  .container-logo {  
    height: 40px; /* Reduced size of the logo */
    margin-left: 40px; /* Add space from the left edge of the page */
    margin-top: none; /* Adjust top margin to lower the logo */
    align-items: center;
  }

   /* This is the Welcome to Neufluence */
  .container-style h2 {  
    text-align: center;
    font-size: 20px;
    color: #fc8320;
  }


 /* This is the dropdown menu */

    .custom-select {
      padding: 15px;
      margin-top: 20px; /* Spacing between elements */
      margin-bottom: 15px; /* Spacing between elements */
      background-color: #fc832030;
      border-radius: 10px;
      border: none;
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      font-weight: 200;
      color: #2c3e50;
    }

    .custom-select:hover {
      background-color: #941eb51f;
    }

 /* This is the Create Account button */

.container-style button {
  padding: 14px;
      background-color: #fc8320; /* Example primary color */
      color: white;
      font-family: 'NunitoSans', sans-serif;   
      font-size: 14px;
      font-weight: 600;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      width: 40%; 
      display: block;
      margin-left: auto;
      margin-right: auto;
    
}

.container-style button:hover {
  background-color: #951eb5; /* Darker shade for hover effect */
}

  /* NOTE: CTA Link  */
  .cta-links p{
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin-top: 6%; 
  
  }
  

  /* End of Sign Up Home Container */


  

   
/************ MEDIA QUERIES *************** MEDIA QUERIES *******************/

/* For average Ipad screens */

  @media only screen and (min-width: 1024px) and (max-width: 1366px) {

        body 
        {
        min-height: 100%;
        padding-bottom: 50px; /* Add bottom padding */
        overflow-x: hidden;
      }

      .sign-home {
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
        justify-content: center;
      }

       /* This is the white form on the SignHome page */

    .container-style {
          width: 70%; /* Fixed width */
          height: auto; /* Adjusted for variable content */
          margin-left: auto; /* Centering the form horizontally */
          margin-right: auto;
  }

  /* This is the dropdown menu */

  .custom-select {
    padding: 25px;
    width: 85%;
    margin-left: auto; /* Spacing between elements */
    margin-right: auto; /* Spacing between elements */
   
  }


   /* This is the button */
   .container-style button {  
    width: 35%; 
    background-color: #fc8320; /* Example primary color */
    color: white;
    padding: 15px;
    font-family: 'NunitoSans', sans-serif;   
      font-size: 14px;
      font-weight: 600;
      border: none;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;
  }

  .cta-links p{ /* media query */
    font-size: 16px;   
    margin-top: 10px; 

}


  }

/* For tablets (width between 769px and 1023px) */

  @media only screen and (min-width: 769px) and (max-width: 1023px) {
   
   
    .sign-home {
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }

    /* This is the white form on the SignHome page */

  .container-style {
    width: 90%; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px; /* Consistent padding */
    display: flex;
    flex-direction: column;
    margin-left: auto; /* Centering the form horizontally */
    margin-right: auto;
  }
  
    body 
    {
    min-height: 100%;
    padding-bottom: auto; /* Add bottom padding */
    padding-top: auto; /* Add bottom padding */
    overflow-x: hidden;
  }

   /* This is the dropdown menu */

   .custom-select {
    padding: 25px;
    width: 80%;
    margin-left: auto; /* Spacing between elements */
    margin-right: auto; /* Spacing between elements */
   
  }
  }

/* iPhone 14 and equivalent devices */
/* For mobile devices (width below 768px) */

  @media screen and (min-width: 481px) and (max-width: 768px) {


    body 
    {
    min-height: 100%;
    padding-bottom: auto; /* Add bottom padding */
    padding-top: auto; /* Add bottom padding */
    overflow-x: hidden;
  }
    .sign-home {
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }

     /* This is the dropdown menu */

  .custom-select {
    padding: 25px;
    width: 85%;
    margin-left: auto; /* Spacing between elements */
    margin-right: auto; /* Spacing between elements */
   
  }


      /* This is the white form on the SignHome page */

      .container-style {
        width: auto; /* Fixed width */
        height: auto; /* Adjusted for variable content */
        padding: 25px; /* Consistent padding */
        display: flex;
        flex-direction: column;
        margin-left: auto; /* Centering the form horizontally */
        margin-right: auto;
        margin-top: auto;
      }

      /* This is the button */
      .container-style button {  
        width: 40%; 
        background-color: #fc8320; /* Example primary color */
        color: white;
        padding: 15px;
        font-family: 'NunitoSans', sans-serif;   
          font-size: 14px;
          font-weight: 600;
          border: none;
          border-radius: 8px;
          margin-left: auto;
          margin-right: auto;
      }

      
      }

   


/* For small mobile devices (width below 480px) */
@media screen and (max-width: 480px) {
 
  .cta-links p{
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    margin-top: 6%; 
  
  }

  .container-style p{
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    margin-top: 6%; 
  
  }
  .container-style { /* background */
    width: 100%; /* Fixed width */
    height: auto; /* Adjusted for variable content */
    display: flex;
    margin-top: 5%; /* Consistent margin from top */
  }
  .custom-select { /* media query */
    padding: 15px;
    margin-top: 20px; /* Spacing between elements */
    margin-bottom: 20px; /* Spacing between elements */
    font-size: 14px;
    font-weight: 200;
  }
  
  .sign-home {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center; /* Centers the content horizontally */
    align-items: start; /* Aligns items to the top */ 
    flex-wrap: wrap; /* Allows elements to wrap if the screen is too small */
}

     /* This is the button */
     .container-style button {  
      width: 50%; 
      padding-left: 40px;
      padding-right: 100px;
        font-size: 14px;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    body 
    {
    min-height: 100%;
    padding-bottom: auto; /* Add bottom padding */
    padding-top: auto; /* Add bottom padding */
    overflow-x: hidden;
  }
}

