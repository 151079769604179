/* This is the template for all forms*/



  
/* This is the positioning of the white box */

 .forms-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 50px 30px 50px;
    border: none;
  }

  /* This is the styling of the white form  */ 
  .forms-box {
    width: 450px;
    min-height: 400px;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 30px 50px 50px;
    flex-direction: column;
    margin-top: 60%; /* Increase top margin to push the box down */
  
    }

/***** PLAN OVERVIEWS  ***********     PLAN OVERVIEWS  *******   *****/
 
            .overview-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 0px 50px 30px 50px;
                border: none;
            }

            /* This is the styling of the Overviews white form  */ 
            .overview-box {
                width: 60%;
                min-height: 400px;
                background-color: #FFFFFF;
                border-radius: 50px;
                padding: 50px 50px 50px;
            
                flex-direction: column;
                margin-top: 15%; /* Increase top margin to push the box down */
            
                }

            .overview-box h2{
                color: #fc8320;
                text-align: center;
                font-size: 25px;
                font-weight: 500;
                margin-top: 10px;
                }

                .overview-box h1{
                    color: #fc8320;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 10px;
                    }

                    .overview-box p{
                        color: #2c3e50;
                        text-align: left;
                        font-size: 16px;
                        font-weight: 500;
                        margin-top: 10px;
                        }

        .overview-container button{
            background-color: #fc8320;
            align-items: center;
            color: white;
            font-size: 16px;
            border-radius: none;
            border: none;
          
            }

          
          


              .creator-section .bullet-group {
                display: flex; /* Make the list items horizontal */
                flex-wrap: wrap; /* Wrap to new lines if needed */
                gap: 10px; /* Spacing between items */
                list-style: none; /* Remove default bullets */
                padding: 0; /* Remove padding */
                margin: 10px 0; /* Add vertical spacing */
                justify-content: center; 
              }
              
              .creator-section .bullet-group li {
                background-color: #941eb5b2;
                color: whitesmoke;
                padding: 10px 16px;
                border-radius: 12px;
                border: 1px solid #951eb5;
                font-size: 16px;
              }
              
/************** END OF  PLAN OVERVIEWS************************              

 
/* Scrolling the fields */
    .dash-scrollable {
    max-height: 400px;
    overflow-y: auto;
   
     }  

/* This is the styling of the contents of the white form  */ 
   .campaigns-style {
    display: block;
    color: red;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    
  }


/* Ensure labels are on top of fields */
   
    .campaigns-style label {
        display: block;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 10px;
        color: #2c3e50;
    }


/* Ensure all input fields are even in width and height */
     
    .campaigns-style input,
    .campaigns-style textarea,
      .campaigns-style select {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        color: #2c3e50;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 14px;
        margin-bottom: 5px;
        margin-top: 15px;
      }


      .cl-button {
        background-color: #951eb5;
        width: 20%;
        color: white;
        border: 1px solid #ccc;
        padding: 10px 10px;
        border-radius: 8px;
        font-size: 14px;
        margin-left: 10px;
        cursor: pointer;
      }
      
      .close-button:hover {
        background-color: #f0f0f0;
      }
      
        

      
/* Heading size and colors */

      .forms-box h2 {
        color: #fc8320;
        font-size: 25px;
        font-weight: 300px;
      text-align: center;
      margin-bottom: 15px;
      margin-top: 15px;
        }

    .forms-box h3 {
          font-size: 20px;
          font-weight: 300px;
        text-align: center;
        color: #fc8320;
}

.form-button {
    background: #fc8320;
    border: none;
    color: whitesmoke;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600px;
    padding: 15px
}
 /* Error message styling */
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
   
  /* Styling the button */

       

    /* Ensure the button is inside a flex container and centered */
.campaigns-style .button-container {
    display: flex;
    justify-content: center; /* Horizontally centers the button */
    align-items: center;
    width: 100%; /* Ensures it spans the full form width */
    margin-top: 15px; /* Adjust as needed */
}


 /* Button hover*/

     .campaigns-style button:hover:not(:disabled) {
     background-color: #fc8320;

     }
        

     .navigation-links {
        display: flex;
        padding: 14px;
        justify-content: space-between;
        margin-top: 0px;
        font-size: 18px;
        color: #fc8320;
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
    }


    .navigation-links a{
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 35px;
        font-size: 18px;
        color: #fc8320;
        text-decoration: none;
        font-weight: 600;
    }
/* Navigation Next and Previous Syling */

  
    
    .navigation-links :hover {
        color: #951eb5;
       
    }
    
    .navigation-links a:disabled {
        color: gray;
        pointer-events: none;
        text-decoration: none;
    }


    .link-button {
        background: none;
        border: none;
        color: #951eb5;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600px;
    }
    .link-button:hover {
        text-decoration: none;
        color: whitesmoke;
    }
    
    
    .back-to-campaign {
        margin-top: 10%; /* Adds space above the link */
        text-align: center; /* Centers the text */
      }

      .back-to-campaign button {
        background: #d211d2;
        border: none;
        border-radius: 5px;
        padding: 10px;
        color: whitesmoke;
        cursor: pointer;
        font-size: 16px;
        font-weight: 800px;
    }
   
      
      .back-to-campaign :hover {
       
        color: whitesmoke;
      }
      

  /* Progress Bar Styling */

  .progress-container {
    width: 100%;
    height: 8px;
    background: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.progress-bar {
    height: 100%;
    background: #951eb5; /* Progress bar color */
    transition: width 0.4s ease-in-out;
}


/* MEDIA QUERIES BEGIN HERE */

/* For tablets (width between 768px and 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .forms-box {
        width: 80%;
        padding: 25px;
        margin-top: 10%;
    }

    .forms-container {
        padding: 0px 30px 20px 30px;
    }

    .campaigns-style button {
        width: 50%;
        font-size: 16px;
        padding: 10px;
    }
}


/* iPhone 14 and equivalent devices */
/* For mobile devices (width below 768px) */
@media screen and (max-width: 768px) {
    .forms-container {
        flex-direction: column;
        margin-top: 10%;
        align-items: center;
        padding: 0px 20px 20px 20px;
    }

    .forms-box {
        width: 80%;
        padding: 20px;
        margin-top: 100px;
        border-radius: 30px;
    }

    .campaigns-style label {
        font-size: 14px;
    }
    

    .campaigns-style input,
    .campaigns-style textarea,
    .campaigns-style select {
        font-size: 14px;
        padding: 10px;
    }

    .forms-box h2 {
        font-size: 24px;
    }

    .forms-box h3 {
        font-size: 18px;
    }

    .campaigns-style button {
        width: 50%;
        font-size: 16px;
        padding: 10px;
    }

    .campaigns-style .button-container {
        justify-content: center;
    }

    body {
        height: 100vh;
        padding-top: 10%;
        padding-bottom: 5px; /* Add bottom padding */
      }
}

/* For small mobile devices (width below 480px) */
@media screen and (max-width: 480px) {
    .forms-box {
        width: 85%;
        padding: 15px;
        margin-top: 25%;
    }

    .forms-container {
        padding: 0px 10px 15px 10px;
    }

    .forms-box h2 {
        font-size: 22px;
    }

    .forms-box h3 {
        font-size: 16px;
    }

    .campaigns-style button {
        font-size: 14px;
        padding: 8px;
    }

    body {
        height: 100vh;
        padding-top: 10px;
        padding-bottom: 5px; /* Add bottom padding */
      }


        /* Progress Bar Styling */

  .progress-container {
    width: 100%;
    height: 8px;
    background: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
}

.progress-bar {
    height: 100%;
    background: #951eb5; /* Progress bar color */
    transition: width 0.4s ease-in-out;
}
}