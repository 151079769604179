

.industry1-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .selected-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

  }
  
  .industry-tag {
    background-color: #fc8320;
    color:whitesmoke;
    font-style: bold;
    border-radius: 12px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .industry-tag button {
    background: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    font-size: 15px;
    color: whitesmoke
  }
  