@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito:wght@400;700&display=swap');

/* This is for the all dashboard componenents  */
 /* Dashboard: Dashboardb.js and Dashboardtest.js componenents */

 /************* BRAND DASHBOARD  *****************/

    .dashboard-container {/* This is the positioning of the white box */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 50px 30px 50px;
    }


      .dashboard-box { /* This is the white box */
      width: 90%;
      min-height: 400px;
      background-color: #FFFFFF;
      border-radius: 50px;
      padding: 20px 50px 40px;
      flex-direction: column;
      margin-top: 40%; /* Increase top margin to push the box down */
      }


      .dashboard-box h2 {
        color: #fc8320;
        font-size: 29px;
        font-weight: 400px;
      text-align: center;
      margin-top: 10px;
        }

        /* Button positioning for the dashboard */
      .button-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .button-row {  /* Horizontal row for buttons on the dashboard */
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 30px;
      }

      .button-status {
        font-size: 11px; /* Smaller font for status */
        display: block;
        margin-top: 5px;
        font-weight: bold;
      }

      .dashboard-button {  /* This is for dashboard.js buttons */
        width: 200%; /* width */
        height: 200px; /*  height */
        display: flex;
        flex-direction: column;
        justify-content: center; 
        font-family: 'Nunito', sans-serif;
        color: #FFFFFF;
        font-size: 25px;
        font-weight: 700;
        align-items: center;
        padding: 20px; /* Adjust padding as needed */
        border: 3px solid #d211d2;
        border-radius: 20px;
        box-sizing: border-box;
        background-color: #fc8320dc;
        cursor: pointer;
        margin-right: 20px; /* Adjust margin as needed */
       
        }
      
        .dashboardtest-button { /* This is for dashboardTEST.js buttons */
          width: 100%; /* Decrease width */
          height: 200px; /* Decrease height */
          display: flex;
          flex-direction: column;
          justify-content: center; 
          font-family: 'Nunito', sans-serif;
          color: #FFFFFF;
          font-size: 20px;
          font-weight: 400;
          align-items: center;
          padding: 20px; /* Adjust padding as needed */
          border: 3px solid #EEEEEE;
          border-radius: 20px;
          box-sizing: border-box;
          background-color: #951eb5;
          cursor: pointer;
          margin-right: 20px; /* Adjust margin as needed */
         
          }
      

/************* BRAND ACCOUNT  *****************/

        .account1-container {
          width: 100%;
          display: fixed;
          justify-content: center;
          align-items: center;
          padding: 0px 50px 30px 50px;
        }
      
        .account1-box {
          width: 300%;
          max-width: 600px; /* Ensures the form is centered and has a reasonable width */
          background-color: #FFFFFF;
          border-radius: 15px;
          padding: 30px;
          margin-top: 60%;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

      
           /* Style the form headings */
          .account1-box h2 {
            color: #fc8320;
            font-size: 26px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 5px;
          }
          .account1-box input,  /* Ensure all input fields are the same size */
          .account1-box textarea,
          .account1-box select {
            width: 100%;
            padding: 11px;
            border: 1px solid #ddd;
            border-radius: 5px;
            box-sizing: border-box;
            font-size: 16px;
            margin-top: 2px;
            margin-bottom: 2px;
          }
  
        /* Ensure labels are on top of fields */
        .account1-box label {
          display: block;
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 3px;
          color: #2c3e50;
        }
        
          /* Two fields per row */
          .form-row {
            display: flex;
            justify-content: space-between;
            gap: 20px; /* Adds space between fields */
            margin-bottom: 15px;
          }
          
          .form-group {
            flex: 1; /* Ensures both fields take equal space */
            display: flex;
            flex-direction: column; /* Ensures label is above field */
          }

                 /* Center the button */
            .button-container {
              display: flex;
              justify-content: center;
              margin-top: 20px;
            }


             /* Style the submit button */
            .account1-box button {
              width: 30%;
              padding: 12px;
              background-color: #fc8320;
              color: white;
              font-size: 16px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              margin-top: 10px;
            }
            
            .account1-box button:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }


           
                 /* Error message styling */
            .error {
              color: red;
              font-size: 14px;
              margin-top: 5px;
            }
  /* BUTTONS*/

/* Button positioning for the dashboard */
.button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 .button-row {  /* Horizontal row for buttons on the dashboard */
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
}

.button-status {
  font-size: 11px; /* Smaller font for status */
  display: block;
  margin-top: 5px;
  font-weight: bold;
}

.campaign-button {  /* This is for dashboard.js buttons */
  width: 200%; /* width */
  height: 200px; /*  height */
  display: flex;
  flex-direction: column;
  justify-content: center; 
  font-family: 'Nunito', sans-serif;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  border: 3px solid #EEEEEE;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: #fc8320;
  cursor: pointer;
  margin-right: 20px; /* Adjust margin as needed */
 
  }

 /******** Begin Test Campaigns List**** */
  
        .account2-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 50px 30px 50px;
        }
        
        .account2-box {
            width: 100%;
            max-width: 600px; /* Ensures the form is centered and has a reasonable width */
            background-color: #FFFFFF;
            border-radius: 15px;
            padding: 30px;
            margin-top: 40%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            flex-direction: row;
          }


                 /* Ensure labels are on top of fields */
        .account2-box label {
          display: block;
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 3px;
          margin-top: 10px;
          color: #2c3e50;
        }

     /* Style the form headings */
    .account2-box h2 {
      color: #fc8320;
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 5px;
    }
    .account2-box input,  /* Ensure all input fields are the same size */
    .account1-box textarea,
    .account2-box select {
      width: 50%;
      padding: 11px;
      border: 1px solid #ddd;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 16px;
      margin-top: 2px;
      margin-bottom: 2px;
    }

       /* Style the submit button */
       .account2-box button {
        width: 50%;
        padding: 12px;
        background-color: whitesmoke;
        color: #2c3e50;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 5px;
      }




      /* ✅ Table Styling */
.campaign-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.campaign-table th,
.campaign-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.campaign-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-align: center;
  color: #2c3e50;
}

.campaign-table tr:hover {
  background-color: #f9f9f9;
}

/* ✅ Campaign Status Styling */
.status-label {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
  
}

/* Color coding based on campaign status */
.status-label.draft {
  background-color: #ffeb3b;
  color: #2c3e50;
}

.status-label.active {
  background-color: #4caf50;
  color: white;
}

.status-label.completed {
  background-color: #2196f3;
  color: white;
}

/* ✅ Campaign Link Styling */
.campaign-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

 /******** End Test Campaigns List**** */






  .stripe-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 30px 50px;
  }

  /* This is for the white box background*/
  .stripe-box {
    width: 90%;
    min-height: 60%;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding-top: 10%;
    padding-left: 30%;
    padding-right: 30%;
    padding-bottom: 5%;
    flex-direction: column;
    margin-top: 45%; /* Increase top margin to push the box down */
  
    }


    .stripe-box h2 {
      color: #fc8320;
      font-size: 29px;
      font-weight: 400px;
    text-align: center;
    margin-top: 10px;
      }
  




        .accounts-container {
          width: 200%;
          display: fixed;
          justify-content: center;
          align-items: center;
          padding: 0px 50px 30px 50px;
        }

  
        .accounts-box {
          width: 300%;
          max-width: 600px; /* Ensures the form is centered and has a reasonable width */
          background-color: #FFFFFF;
          border-radius: 15px;
          padding: 30px;
          margin-top: 120px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
  

  
  /* Style the form rows for better spacing */
  .form-group {
    margin-bottom: 10px;
  }

 
  
 
  
 
  
  

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
 
}

  

 
  


  
  .greeting-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .greeting-text {
    opacity: 0.5;
  }
  
 
  



  
  /* Add additional styles for specific buttons if needed */

  
  
  /* ... Add more classes for other buttons and elements as needed ... */
  

  
.button-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .button-image {
    top: 10%; /* Adjust as needed */
    width: auto; /* or a specific width */
    height: auto; /* or a specific height */
  }
  
  .button-text {
    bottom: 10%; /* Adjust as needed */
    /* Text styling */
  }

/************ MEDIA QUERIES *************** MEDIA QUERIES *******************/

/* For average Ipad screens */

@media only screen and (min-width: 1024px) and (max-width: 1366px) {

      /* Brand Account 1024px-1366px Media Query */
        
                .account1-container {
                  width: 90%;
                  display: fixed;
                  justify-content: center;
                  align-items: center;
                  padding: 0px 50px 30px 50px;
                }
        
                .account1-box {
                  width: 100%;
                  max-width: 600px; /* Ensures the form is centered and has a reasonable width */
                  background-color: #FFFFFF;
                  border-radius: 15px;
                  padding: 20px;
                  margin-top: 40%;
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
        
        
                .account1-box h2 {
                  color: #fc8320;
                  font-size: 25px;
                  font-weight: 400px;
                 text-align: center;
                  }
        
                  .account1-box p {
                 
                    font-size: 18px;
                    font-weight: 400px;
                   text-align: center;
                    }
        
        
                    .account1-box input,  /* Ensure all input fields are the same size */
                    .account1-box textarea,
                    .account1-box select {
                      color: #2c3e50;
                      font-size: 16px;
                      margin-top: 2px;
                      margin-bottom: 2px;
                    }
        
        
             
                    .account1-box label {     /* Ensure labels are on top of fields */
                      display: block;
                      font-weight: 400;
                      font-size: 18px;
                      margin-bottom: 3px;
                      color: #2c3e50;
                    }
        
                     
                    .form-row {   /* Two fields per row */
                      display: flex;
                      justify-content: space-between;
                      gap: 5px; /* Adds space between fields */
                      margin-bottom: 10px;
                    }
                    
                    .form-group {
                      flex: 1; /* Ensures both fields take equal space */
                      display: flex;
                      flex-direction: column; /* Ensures label is above field */
                    }
        
                         
              
                .button-container {     /* Center the button */
                  display: flex;
                  justify-content: center;
                  margin-top: 15px;
                  margin-left: auto;
                }
                
        
        
                .account1-box button {
                  width: 30%;
                  padding: 12px;
                  background-color: #fc8320;
                  color: white;
                  font-size: 16px;
                  border: none;
                  border-radius: 5px;
                  cursor: pointer;
                  margin-top: 10px;
                }
        
          /**** End of  Brand Account 1024px-1366px Media Query */



}

/* For tablets (width between 769px and 1023px) */
@media only screen and (min-width: 769px) and (max-width: 1023px) {

    /* Brand Account 769px-1023px Media Query */
        
        .account1-container {
          width: 80%;
          display: fixed;
          justify-content: center;
          align-items: center;
          padding: 0px 50px 30px 50px;
        }

        .account1-box {
          width: 92%;
          max-width: 600px; /* Ensures the form is centered and has a reasonable width */
          background-color: #FFFFFF;
          border-radius: 15px;
          padding: 20px;
          margin-top: 40%;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }


        .account1-box h2 {
          color: #fc8320;
          font-size: 22px;
          font-weight: 400px;
         text-align: center;
          }

          .account1-box p {
         
            font-size: 18px;
            font-weight: 400px;
           text-align: center;
            }


            .account1-box input,  /* Ensure all input fields are the same size */
            .account1-box textarea,
            .account1-box select {
              color: #2c3e50;
              font-size: 16px;
              margin-top: 2px;
              margin-bottom: 2px;
            }


     
            .account1-box label {     /* Ensure labels are on top of fields */
              display: block;
              font-weight: 400;
              font-size: 18px;
              margin-bottom: 3px;
              color: #2c3e50;
            }

             
            .form-row {   /* Two fields per row */
              display: flex;
              justify-content: space-between;
              gap: 5px; /* Adds space between fields */
              margin-bottom: 10px;
            }
            
            .form-group {
              flex: 1; /* Ensures both fields take equal space */
              display: flex;
              flex-direction: column; /* Ensures label is above field */
            }

                 
      
        .button-container {     /* Center the button */
          display: flex;
          justify-content: center;
          margin-top: 15px;
          margin-left: auto;
        }
        


        .account1-box button {
          width: 40%;
          padding: 12px;
          background-color: #fc8320;
          color: white;
          font-size: 18px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
        }

  /**** End of  Brand Account 769px-1023px Media Query */


}

/* iPhone 14 and equivalent devices */
@media screen and (min-width: 481px) and (max-width: 768px) {
 
         /* Brand Account 481-768px Media Query */
        
         .account1-container {
          width: 85%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 50px 30px 50px;
        }

        .account1-box {
          width: 92%;
          max-width: 600px; /* Ensures the form is centered and has a reasonable width */
          border-radius: 15px;
          padding: 20px;
          margin-top: 40%;
        }


        .account1-box h2 {
          color: #fc8320;
          font-size: 25px;
          font-weight: 400px;
         text-align: center;
          }

          .account1-box p {
         
            font-size: 18px;
            font-weight: 400px;
           
            }


            .account1-box input,  /* Ensure all input fields are the same size */
            .account1-box textarea,
            .account1-box select {
              width: 85%;
              color: #2c3e50;
              font-size: 16px;
              margin-top: 2px;
              margin-bottom: 2px;
            }


     
            .account1-box label {     /* Ensure labels are on top of fields */
              display: block;
              font-weight: 400;
              font-size: 18px;
              margin-bottom: 3px;
              color: #2c3e50;
            }

             
            .form-row {   /* Two fields per row */
              display: flex;
              justify-content: space-between;
              gap: 5px; /* Adds space between fields */
              margin-bottom: 10px;
            }
            
            .form-group {
              flex: 1; /* Ensures both fields take equal space */
              display: flex;
              flex-direction: column; /* Ensures label is above field */
            }

                 
      
        .button-container {     /* Center the button */
          display: flex;
          justify-content: center;
          margin-top: 15px;
          margin-left: auto;
        }
        


        .account1-box button {
          width: 40%;
          padding: 12px;
          background-color: #fc8320;
          color: white;
          font-size: 16px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
        }

  /**** End of  Brand Account 481px to 768px Media Query */
       
  
        .dashboard-container, .stripe-container {
          flex-direction: column;
          padding: 10px;
        }
      
      
      .dashboard-button {
        width: 100%; /* Decrease width */
        height: 100px; /* Decrease height */
        flex-direction: column;
        justify-content: center;
        font-family: 'Nunito', sans-serif;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 700;
        align-items: center;
        padding: 20px; /* Adjust padding as needed */
        cursor: pointer;
        margin-right: 10px; /* Adjust margin as needed */ 
        }
       
    
    
      .form-row {
        flex-direction: column;
        gap: 10px;
      }
        .dashboard-box, .stripe-box,   .accounts-box{
          width: 79%;
          padding: 20px;
          margin-top: 30px;
        }
        .dashboard-box h2 {
          color: #fc8320;
          font-size: 18px;
          font-weight: 400px;
        text-align: center;
          }
      
      
          .dashboard-box p {
            font-size: 14px;
            font-weight: 400px;
          text-align: center;
            }   

      
        }

      
      



/* iPhone 14 and SE equivalent devices */

@media only screen and (max-width: 480px)  {


        /* Brand Account 480 Media Query */
        
        .account1-container {
          width: 80%;
          display: fixed;
          justify-content: center;
          align-items: center;
          padding: 0px 50px 30px 50px;
        }

        .account1-box {
          width: 92%;
          max-width: 600px; /* Ensures the form is centered and has a reasonable width */
          background-color: #FFFFFF;
          border-radius: 15px;
          padding: 20px;
          margin-top: 180%;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }


        .account1-box h2 {
          color: #fc8320;
          font-size: 18px;
          font-weight: 400px;
         text-align: center;
          }

          .account1-box p {
         
            font-size: 14px;
            font-weight: 400px;
           text-align: center;
            }


            .account1-box input,  /* Ensure all input fields are the same size */
            .account1-box textarea,
            .account1-box select {
              color: #2c3e50;
              font-size: 14px;
              margin-top: 2px;
              margin-bottom: 2px;
            }


     
            .account1-box label {     /* Ensure labels are on top of fields */
              display: block;
              font-weight: 400;
              font-size: 16px;
              margin-bottom: 3px;
              color: #2c3e50;
            }

             
            .form-row {   /* Two fields per row */
              display: flex;
              justify-content: space-between;
              gap: 5px; /* Adds space between fields */
              margin-bottom: 10px;
            }
            
            .form-group {
              flex: 1; /* Ensures both fields take equal space */
              display: flex;
              flex-direction: column; /* Ensures label is above field */
            }

                 
      
        .button-container {     /* Center the button */
          display: flex;
          justify-content: center;
          margin-top: 15px;
          margin-left: auto;
        }
        


        .account1-box button {
          width: 60%;
          padding: 12px;
          background-color: #fc8320;
          color: white;
          font-size: 16px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
        }

  /**** End of  Brand Account 480 Media Query */
       
      .stripe-container {
            flex-direction: column;
            padding: 10px;
          }

          .accounts-style {
            width: 50%; /* Fixed width */
            height: auto; /* Adjusted for variable content */
            background-color: #FFFFFF;
            border-radius: 20px;
            padding: 30px; /* Consistent padding */
            display: flex;
            flex-direction: column;
            margin-top: auto; /* Consistent margin from top */
            margin-left: auto; /* Centering the form horizontally */
            margin-right: auto;
          }



          .dashboard-container {
            flex-direction: column;
            padding: 10px;
          }
        
        .stripe-box {
            width: 90%;
            padding: 20px;
            margin-top: 10%;
            margin-bottom: 10%;
          }

          .dashboard-box {
            width: 88%;
            padding: 12px;
            margin-top: 10%;
            margin-bottom: 10%;
        
          }
        

 
        
              .dashboard-box h2 {
                color: #fc8320;
                font-size: 16px;
                font-weight: 400px;
              text-align: center;
                }
            .dashboard-box p {
              font-size: 14px;
              font-weight: 400px;
            text-align: center;
              }
        
        
        
        
          .dashboard-button {
            width: 90%; /* Decrease width */
            height: 100px; /* Decrease height */
            flex-direction: column;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            align-items: center;
            padding: 20px; /* Adjust padding as needed */
            cursor: pointer;
            margin-right: 10px; /* Adjust margin as needed */ 
            }
          
            .button-row {  /* Horizontal row for buttons on the dashboard */
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-bottom: 30px;
              margin-left: 5px;
            }
        
        
          .form-row {
            flex-direction: column;
            gap: 10px;
          }
} 