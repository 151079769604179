
/* design for SignUpB, SignUpC page */
  
.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
 
}

.error-message {
  color: red;
  margin-top: 5px;
  margin-bottom: 10px;
}

.field-container {
  margin-bottom: 5%; /* Adjust as needed */
}

/****** SignUpB page *******/
/****** Typeform Style *******/

/* Terms and Privacy Policy*/

.term-links {
  font-size: 11px;
  font-family: 'Nunito', sans-serif;
  margin-top: 30px; 

}

.term-links a {
  color: #fc8320; /* Replace with your desired color */
  margin-left: 3px; /* Adds space to the left of each link */
  font-size: 12px;
  margin-right: 3px;
}

.term-links  a:hover {
  text-decoration: underline; /* Replace with the color for hover state */
}


.questionnaire-container {
  width: 400px; /* Fixed width */
    height: 100%; /* Fixed maximum height */
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 50px;
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 50%;
    margin-left: auto;
    margin-right: auto;
  }

    /* This is the contents of the white form  */
    .questionnaire-style {
      display: block;
      color: #2c3e50;
      text-align: left;
      font-family: 'Nunito', sans-serif;
      align-items: center;
    
    }

        /* heading Create Account */

  .questionnaire-style h2 {
    text-align: center;
    font-size: 20px;
    color: #fc8320;
  }

  .questionnaire-style p {
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    color: #2c3e50;
  }

  .questionnaire-style vertical-stack {
   
    flex-direction: column;
    margin-top: 10px; /* Adjust as needed */
  }
    

   /* This is for the subtext below the heading */
   .questionnaire-style-heading {
    text-align: center;
    color: #2c3e50;
    font-family: 'Nunito Sans', sans-serif;
    
  }
 

 /* These are the fields in the form*/

  .questionnaire-style input[type="text"], 
  .questionnaire-style input[type="password"], 
  .questionnaire-style input[type="url"],
  .questionnaire-style input[type="email"],
  .questionnaire-style input[type="website"],
  .questionnaire-style select  {
    width: 100%;
    padding: 10px;
    margin-top: 2px; /* margin above the field */
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 16px;
    color: #2c3e50;
  }

 
  .questionnaire-style input[placeholder] {
   font-size: 12px;
    font-weight: 100;
 
  }

  .questionnaire-style button {
    padding: 14px;
    background-color: #fc8320; /* Example primary color */
    color: white;
    font-family: 'NunitoSans', sans-serif;   
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 65%; 
    display: block;
    margin-left: 30%;
    }
    
    .questionnaire-style button:hover {
      background-color: #951eb5; /* Darker shade for hover effect */
      color: white;
    }
/****** END OF SignUpB page *******/

  /* For the non-Typeform forms  */
  .questionnaire2-container {
    width: 400px; /* Fixed width */
      height: 100%; /* Fixed maximum height */
      background-color: #FFFFFF;
      border-radius: 20px;
      padding: 50px;
      padding-top: 20px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      margin-top: 70%;
      margin-left: auto;
      margin-right: auto;
    }


  



  
/************ MEDIA QUERIES *************** MEDIA QUERIES *******************/

/* For average Ipad screens */


@media only screen and (min-width: 1024px) and (max-width: 1366px) {

  body 
  {
  min-height: 100%;
  padding-bottom: 50px; /* Add bottom padding */
  overflow-x: hidden;
}

}


/* For tablets (width between 769px and 1023px) */
@media only screen and (min-width: 769px) and (max-width: 1023px) {
       
      .questionnaire-container {
            width: 60%;
            padding: 30px;
            margin-top: 10%;
          }

          .questionnaire-style input, .questionnaire-style select {
            width: 95%;
          }

          .questionnaire-style button {
            width: 80%;
            margin-left: 10%;
          }

          .questionnaire2-container {
            width: 60%; /* Fixed width */
              padding: 50px;
              padding-top: 20px;
              padding-bottom: 10px;
              display: flex;
              flex-direction: column;
              margin-top: 70%;
              margin-left: auto;
              margin-right: auto;
            }
}



@media only screen and (min-width: 481px) and (max-width: 768px) {
  .questionnaire-container {
    width: 50%; /* Fixed width */
  
      padding: 50px;
      padding-bottom: 10px;
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
    }

    body 
    {
    min-height: 100%;
    padding-bottom: 50px; /* Add bottom padding */
    padding-top: 30px; /* Add bottom padding */
    overflow-x: hidden;
  }
  }


/* iPhone SE and equivalent devices */

@media only screen and (max-width: 480px) {
  .questionnaire-container {
    width: 80%;
    padding: 20px;
    margin-top: 30%;
  }

     /* This is the contents of the white form  */
     .questionnaire-style {
      display: block;
      color: #2c3e50;
      text-align: left;
      font-family: 'Nunito', sans-serif;
      align-items: center;
    margin-bottom: auto;
    }

  .questionnaire-style h2 {
    text-align: center;
    font-size: 16px;
    color: #fc8320;
  }
  .questionnaire-style input, .questionnaire-style select {
    width: 100%;
  }

  .questionnaire-style button {
    width: 100%;
    margin-left: 0;
  }

  .questionnaire-style p {
    font-size: 12px;
    text-align: center;
    color: #2c3e50;
  }
}





