


/* ✅ Campaign Summary (Read-Only Mode) */
/* ✅ Campaign Summary Container */
.campaign-summary {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
    position: relative; /* ✅ Allows moving down without affecting layout */
    top: 150px; /* ✅ Moves it 80px down */
}

.campaign-summary h2 {
    color: #fc8320;
    margin-bottom: 30px;
    text-align: center;
  
}

/* ✅ Two-Column Grid Layout */
.campaign-summary-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    gap: 15px;
    margin-top: 10px;
}

/* ✅ Full-Width Campaign Description */
.campaign-summary-desc {
    grid-column: span 2; /* Make it take full width */
    font-size: 16px;
    font-weight: normal;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
}

/* ✅ Labels and Values Styling */
.campaign-summary p {
    font-size: 16px;
    color: #2c3e50;
    margin: 5px 0;
}

/* ✅ Status Label */
.status-label {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    display: inline-block;
}

/* ✅ Status Colors */
.status-label.draft {
    background-color: #ffeb3b;
    color: #333;
}

.status-label.active {
    background-color: #4caf50;
    color: white;
}

.status-label.completed {
    background-color: #2196f3;
    color: white;
}

/* ✅ Buttons */

/* ✅ Center the buttons container */
.campaign-summary-buttons {
    display: flex;
    justify-content: center;  /* Centers buttons horizontally */
    gap: 15%;  /* Adds space between buttons */
    margin-top: 20px;
}

.campaign-summary button,
.campaign-summary a {
    display: inline-block;
    padding: 10px 15px;
    margin-top: 15px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    transition: background 0.3s;
  
}

.campaign-summary button {
    background-color: #951eb5;
    color: white;
    border: none;
    cursor: pointer;

 
}

.campaign-summary-desc label {
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 5px;
    display: block;
}


.campaign-summary button:hover {
    background-color: #0056b3;
}

.campaign-summary a {
    background-color: #fc8320;
    color: white;
}

.campaign-summary a:hover {
    background-color: #5a6268;
}
/* ✅ Edit Mode (Form) */
.campaign-edit {
    background: white;
    padding: 20px;
    padding-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 120%; /* ✅ Increased width for better layout */
    margin-top: 20%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* ✅ 2-column layout */
    gap: 15px;
    position: relative; /* ✅ Allows moving down without affecting layout */
    top: 170px; /* ✅ Moves it 80px down */
}

.campaign-edit h2 {
    color: #fc8320;
    margin-bottom: 30px;
  
}

/* ✅ Labels */
.campaign-edit label {
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 5px;
    display: block;
}

/* ✅ Inputs, Textareas, and Select */
.campaign-edit input,
.campaign-edit select {
    width: 50%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #2c3e50;
}


.campaign-edit textarea {
    width: 90%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #2c3e50;
}
/* ✅ Make Campaign Description Span Full Width */
.campaign-edit textarea[name="campaignDesc"] {
    grid-column: span 2; /* ✅ Full row */
    height: 100px; /* ✅ Larger text area */
}

/* ✅ Buttons - Centered Below the Form */
.campaign-edit button {
   width: 50%;
    background-color: #951eb5;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    transition: background 0.3s;
}

.campaign-edit button:hover {
    background-color: #218838;
}



/* ✅ Responsive Styling */
@media only screen and (max-width: 768px) {
    .campaign-summary,
    .campaign-edit {
        width: 90%;
        padding: 15px;
    }

    .campaign-summary h2,
    .campaign-edit label {
        font-size: 18px;
    }

    .campaign-summary p {
        font-size: 14px;
    }

    .campaign-edit {
        grid-template-columns: 1fr; /* ✅ Single column layout on smaller screens */
    }

    .campaign-edit textarea[name="campaignDesc"] {
        grid-column: span 1; /* ✅ Single column */
    }

    .campaign-edit button {
        grid-column: span 1; /* ✅ Buttons stack properly */
}
}